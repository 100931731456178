(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.powerTool = {

        vue: '',
        setup: function(){
            this.powerTool();
        },
        powerTool : function (){
            // product filter
            this.vue = new Vue({//
                // vue container
                el: '#v-power-tool',
                // Vue data fields
                data: {
                    industries: INDUSTRIES,
                    results: RESULTS,
                    threshold_targets: THRESHOLD_TARGETS,
                    target_hours: CURRENT_SETTINGS.target_hours,
                    currentIndustry: CURRENT_SETTINGS.industry,
                    devicesAllowed: 8,
                    selectedDevices: CURRENT_SETTINGS.devices,
                    batteries: CURRENT_SETTINGS.batteries,
                    input_voltage: CURRENT_SETTINGS.voltage,
                    inverters: [],
                    popupActive : false,
                    emailForm: false,
                    base_link: BASE_LINK,
                    mobileStep : 1, 
                },
                computed: {
                    
                    result_percent: function(){
                        var result_percent = this.runTimeHours / this.target_hours;
                        return result_percent * 100;
                    },
                    result_color: function(){

                        var result_color = "red";
                        if(this.result_percent >= this.threshold_targets.yellow){
                            result_color = "yellow";
                        }
                        if(this.result_percent >= this.threshold_targets.green){
                            result_color = "green";
                        }
                        return result_color;
                    },


                    activatedDevices: function(){
                        var devices = [];
                        if( this.selectedDevices.length > 0){
                            this.selectedDevices.forEach( function(object) {
                                if( object.isActive ){
                                    devices.push(object);
                                }
                            });
                        }
                        return devices; 
                    },
                    activatedDeviceCount: function(){
                        return this.activatedDevices.length;
                    },
                    runTimeWatts: function(){
                        var runWatts = 0;
                        if( this.activatedDevices.length > 0){
                            this.activatedDevices.forEach( function(object) {
                                runWatts += parseInt(object.specs.watts);
                            });
                        }
                        if(runWatts > 3000){
                            // runWatts = 3000;
                        }
                        return runWatts; 
                    },
                    runTimeHours: function(){
                        var runTime = 0;
                        runTime = this.runTimeWatts * 1.1;
                        runTime = runTime / this.input_voltage;
                        runTime = (this.batteries * 100 ) / runTime;

                        runTime = runTime / 2; // Only drain to 50%;

                        // return Math.round(runTime); // No Decimal
                        return Math.round(runTime * 10) / 10; // 1 Decimal
                    },
                    desktopStep: function(){
                        var count = 1;

                        if(this.currentIndustry){
                            count = 2;

                            if(this.target_hours){
                                count = 3;
                            }
                            if(this.selectedDevices.length > 0){
                                count = 4;
                            }
                        }
                        return count;
                    },
                    sineWave: function(){
                        var sineWave = 'modified_sine_wave'; // Need more info on how this works
                        // Loop through each activated Device
                        if( this.activatedDevices.length > 0){
                            this.activatedDevices.forEach( function(device) {
                                // If any of the devices are 'Pure' sine wave than the pure sine wave is used. 
                                if(device.specs.sine_wave === "psw"){
                                    sineWave = "pure_sine_wave";
                                }
                            });
                        }
                        return sineWave;
                    },
                    wattsNeeded: function(){
                        largestWatts = 0,
                        largestWattsWithSurge = 0,
                        totalWatts = 0,
                        totalWattsWithSurge = 0,
                        wattsNeeded = 0,
                        _this = this;
                        // Loop through each activated Device
                        if( this.activatedDevices.length > 0){
                            this.activatedDevices.forEach( function(device) {
                                
                                // Calculate the Largest Watts device
                                if(device.specs.watts * device.specs.x_factor_serge > largestWatts ){
                                    largestWatts = device.specs.watts; // Used for removing watts from totalWatts calculation
                                    largestWattsWithSurge = device.specs.watts * device.specs.x_factor_serge; // One for calculations
                                }
                                // Calculate the total watts of all the items
                                totalWatts += parseInt(device.specs.watts);
                                totalWattsWithSurge += parseInt(device.specs.watts * device.specs.x_factor_serge);
                            });
                        }
                        // Watts Needed is whichever is higher, the totalWatts (no surge) (minus the largestWatts) or the Largest Watts (including surge)) 
                        wattsNeeded = totalWattsWithSurge;

                        return wattsNeeded;
                    },
                    // Background Image
                    bodyStyle: function(){
                        if(this.currentIndustry){
                            return {
                                'background': 'url(' + this.currentIndustry.background_image.url + ') no-repeat center center / cover', 
                                'background-size': 'cover',
                            };
                        }else{
                            return {};
                        }
                    },

                    // URL Variables
                    current_link: function(){
                        var prefix = "?";
                        var current_link = "";

                        if(this.currentIndustry){
                            current_link += prefix + "industry=" + this.currentIndustry.industry_name;
                            prefix = "&";
                        }
                        if(this.selectedDevices.length > 0){
                            current_link += prefix + "devices=" + this.selected_devices_url;
                            prefix = "&";
                        }
                        if(this.batteries > 1 ){
                            current_link += prefix + "batteries=" + this.batteries;
                            prefix = "&";
                        }
                        if(this.input_voltage != "12" ){
                            current_link += prefix + "voltage=" + this.input_voltage;
                            prefix = "&";
                        }
                        if(this.target_hours != "1" ){
                            current_link += prefix + "target=" + this.target_hours;
                            prefix = "&";
                        }

                        return BASE_LINK + current_link;
                    },

                    selected_devices_url : function() {
                        var selected_devices_url = "";

                        if(this.selectedDevices.length > 0){
                            this.selectedDevices.forEach( function(object) {
                                selected_devices_url += object.deviceID ;
                                selected_devices_url += (object.isActive)? '-a' : '-i';
                                selected_devices_url += ",";
                            });
                            selected_devices_url = selected_devices_url.substring( 0, selected_devices_url.length - 1 );
                        }
                        return selected_devices_url;
                    },

                    isStepComplete: function() {
                        console.log(this);
                        console.log(this.currentIndustry);
                        switch (this.mobileStep){
                            case (1):
                                return false;
                            break;
                            case (2):
                                return (this.target_hours > 0);
                            break;
                            case (3):
                                return (this.selectedDevices.length > 0);
                            break;
                            case (4):
                                return true;
                            break;
                            case (5):

                            break;
                            case (6):

                            break;
                        }
                        return false;
                    },

                    /// Next step
                },
                mounted: function(){
                    
                    if( ! Cookies.get('samlex_power_tool_popup') ){
                        $('.pt-power-tool').addClass('is-popup-active__instructions is-popup-active__instructions--initial');
                        Cookies.set('samlex_power_tool_popup', true, { expires: 7 })
                    }


                    if(this.currentIndustry){
                        this.mobileStep = 2;

                        if(this.target_hours > 0 ){
                            this.mobileStep = 3;
                        }
                        if(this.selectedDevices > 0 ){
                            this.mobileStep = 4;
                        }
                        if(this.activatedDeviceCount > 0 ){
                            this.mobileStep = 5;
                        }
                    }

                    this.getInverters();
                },
                watch: {

                    emailForm: function(){
                        this.updateInvertersHTML();
                        if(this.emailForm){
                            $('.pt-power-tool').addClass('is-email-form');
                        }else{
                            $('.pt-power-tool').removeClass('is-email-form');
                        }
                    },
                    current_link : function(){
                        $('.j-power-tool--link').attr('href', this.current_link );
                        history.replaceState('Power Tool', 'Power Tool', this.current_link );
                    },
                    popupActive: function(){
                        this.updateInvertersHTML();
                        if(this.popupActive){
                            $('.pt-power-tool').addClass('is-popup-active');
                        }else{
                            $('.pt-power-tool').removeClass('is-popup-active');
                        }
                    },
                    currentIndustry: function(){
                        // When the indusrty changes reset the selected devices
                        this.selectedDevices = [];

                        if(this.currentIndustry){
                            this.mobileStep = 2;
                        }
                    },
                    batteries : function(){
                        if(this.batteries < 1){
                            this.batteries = 1;
                        }
                    },
                    runTimeWatts :  function () {
                        if(this.runTimeWatts > 0){
                            this.getInverters();
                        }else{
                            this.inverters = [];
                        }
                    },
                    input_voltage :  function () {
                        if(this.runTimeWatts > 0){
                            this.getInverters();
                        }else{
                            this.inverters = [];
                        }
                    }
                },
                methods: {

                    /*
                    * Set current Industry
                    */

                    sortable: function(){
                        sortable('.j-sortable', 'destroy');
                        sortable('.j-sortable', {
                            forcePlaceholderSize: true,
                            handle: '.pt-device--handle',
                        });
                    },
                    /*
                    * Device Controls
                    */
                    // Add Device to Selected Devices List
                    addDevice: function(deviceIndex){
                        if( this.selectedDevices.length < this.devicesAllowed ){
                            var device = Object.assign({}, this.currentIndustry.devices[deviceIndex]);
                            device.deviceIndex = deviceIndex;
                            device.isActive = false;
                            this.selectedDevices.push(device);
                            // this.selectedDevices.push(deviceIndex);
                        }
                    },  
                    // Remove Device from Selected Devices List (if applicapble)
                    removeDevice: function(deviceIndex){
                        if( this.selectedDevices.length > 0){
                            var count = 0;
                            var selectedDevices = this.selectedDevices;
                            var removed = false;
                            for( var loopIndex = selectedDevices.length - 1; loopIndex >= 0; loopIndex-- ){
                                if(!removed){
                                    if( selectedDevices[loopIndex].deviceID === deviceIndex && ! selectedDevices[loopIndex].isActive ){
                                        selectedDevices.splice(loopIndex, 1);
                                        removed = true;
                                    }
                                }
                                count++; 
                            }
                            if(!removed){
                                for( var loopIndex = selectedDevices.length - 1; loopIndex >= 0; loopIndex-- ){
                                    if(!removed){
                                        if( selectedDevices[loopIndex].deviceID === deviceIndex ){
                                            selectedDevices.splice(loopIndex, 1);
                                            removed = true;
                                        }
                                    }
                                    count++; 
                                }
                            }

                            this.selectedDevices = selectedDevices;
                        }
                    },  
                    // Count  how many times a device has been selected
                    deviceCount: function(deviceIndex){
                        var count = 0;
                        if( this.selectedDevices.length > 0){
                            this.selectedDevices.forEach( function(object) {
                                if( object.deviceID === deviceIndex){
                                    count++;
                                }
                            });

                        }
                        return count;
                    },
                    updateInvertersHTML: function(){
                        var email = $('.j-inverters--email').html();
                        $('#input_3_3').val(email);
                        


                        var results = $('.j-inverters--results').html();
                        $('.j-inverters--container').html(results);
                        
                        
                        
                        
                        
                        var message = "";
                        var length = $('.j-inverters--results').find('.pt-results-inverter').length;
                        if(length == 1){
                            message = "We found 1 inverter for you!";
                        }else{
                            message = "We found " + length + " inverters for you!";
                        }
                        $('.j-inverters--message').html(message);
                    },
                    // Toggles a selected devices active state
                    toggleDeviceActivation: function(device){
                        device.isActive = !device.isActive;
                        return device;
                    },  
                    deactivateAllDevices : function(){
                        if( this.selectedDevices.length > 0){
                            this.selectedDevices.forEach( function(object) {
                                object.isActive = false;
                            });

                        }
                    },

                    /*
                    * Inverter Search
                    */
                    toggleResultsPopup : function(){
                        this.popupActive = !this.popupActive;
                        this.emailForm = false;
                    },
                    getInverters: function(){
                        this.inverters = [];
                       
                        var inverters = [];

                        if(this.activatedDeviceCount > 0){

                            for ( var key in this.results[this.sineWave]){
                                result = this.results[this.sineWave][key];
                                
                                
                                if(this.wattsNeeded <= result.max_wattage){
                                    if(result[this.input_voltage + 'v'][0]){
                                        inverters = result[this.input_voltage + 'v'];
                                    }
                                    break;
                                }
                            }
                        }
                        this.inverters = inverters;
                        this.updateInvertersHTML();
                    },
                    goBackStep: function(){
                        switch (this.mobileStep){
                            case (2):
                                this.currentIndustry = false;
                                this.target_hours = 0;
                            break;
                            case (3):
                                this.selectedDevices = [];
                            break;
                            case (4):
                                this.batteries = 1;
                            break;
                            case (5):
                                this.deactivateAllDevices();
                            break;
                            case (6):

                            break;
                        }
                        this.mobileStep--;
                    },
                },
            });
        },
    };
}(window.jQuery, window, document)); // End of jQuery wrapper