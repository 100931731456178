(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        menu: "",
        menuToggle: "",

        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.
        smoothScroll : function (){
            $('a[href^="#"]').click(function () {
                var scrollTarget;
                // If the link is just # set scrollTarget to top of page
                if($(this).attr('href') === '#'){
                    scrollTarget = 0; //
                }else{ // Otherwise locate the target element and find its offset from the top
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            scrollTarget =  target.offset().top;
                        }
                    }
                }
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget
                }, 1000);
                return false; // Exit.
            });
        },
        testimonial: function(){

            var testimonials =  $('.j-testimonials'); 
            if(testimonials.length > 0){
                testimonials.each(function(){
                    var _this = $(this);
                    _this.find('.j-testimonials--slideshow').slick({
                        arrows: true,
                        dots: true,
                        nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                        prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
                        appendArrows: _this.find('.o-carousel--controls'),
                        appendDots: _this.find('.o-carousel--controls'),
                    });
                });
            }
        },
        accordion: function(){
            $('.j-accordion').on('click','.j-accordion--toggle',function(){
                var _this = $(this);
                // var accordion = _this.parents('.j-accordion');
                var single =  _this.parents('.j-accordion--single');
                single.toggleClass('is__open').find('.j-accordion--body').slideToggle();
                // single.siblings('.is__open').removeClass('is__open').find('.j-accordion--body').slideUp();
            });

            $('.j-accordion-mobile--toggle').on('click',function(){
                $(this).next().slideToggle();
            })
        },
        bannerCarousel: function(){
            var bannerSlideshow =  $('.j-slideshow--banner'); 
            if(bannerSlideshow.length > 0){
                bannerSlideshow.each(function(index, bannerSlideshowSingle){
                    bannerSlideshowSingle = $(bannerSlideshowSingle);
                    var args = {
                        dots: false,
                        arrows: true,
                        infinite: true,
                        nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                        prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>'
                    };
                    if(bannerSlideshowSingle.data('speed')){
                        args.autoplay = true;
                        args.autoplaySpeed = bannerSlideshowSingle.data('speed');
                    }

                    bannerSlideshowSingle.slick(args);
                });
            }

        },
        calloutCarousel: function(){
            var calloutSlideshow =  $('.j-slideshow--callout'); 
            if(calloutSlideshow.length > 0){
                calloutSlideshow.each(function(index, calloutSlideshowSingle){
                    calloutSlideshowSingle = $(calloutSlideshowSingle);
                    var args = {
                        dots: true,
                        arrows: true,
                        infinite: true,
                        adaptiveHeight: true,
                        nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                        prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>'
                    };
                    if(calloutSlideshowSingle.data('speed')){
                        args.autoplay = true;
                        args.autoplaySpeed = calloutSlideshowSingle.data('speed');
                    }

                    calloutSlideshowSingle.slick(args);
                });
            }
        },
        productGalleryCarousel: function(){
            var productSlideshow =  $('.j-slideshow--product-gallery'); 
            if(productSlideshow.length > 0){
                productSlideshow.slick({
                    dots: true,
                    arrows: true,
                    infinite: true,
                    appendDots: '.o-product-gallery--dots',
                    customPaging : function(slider, i) {
                        return '<span class="s-dots--image"><img src="' + $(slider.$slides[i]).find('.o-product-gallery--image').data('thumbnail') + '" /></a>';
                    },
                    nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                    prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>'
                });
            }
        },
        videoCarousel: function(){
            var videoSlideshow =  $('.j-videos'); 
            if(videoSlideshow.length > 0){
                videoSlideshow.each(function(){
                    var _this = $(this);
                    _this.find('.j-videos--slideshow').slick({
                        dots: true,
                        arrows: true,
                        infinite: true,
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                        prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
                        appendArrows: _this.find('.o-carousel--controls'),
                        appendDots: _this.find('.o-carousel--controls'),
                        responsive: [
                            {
                              breakpoint: 1000,
                              settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                              }
                            },
                            {
                              breakpoint: 800,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll:2,
                              }
                            },
                            {
                              breakpoint: 500,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                              }
                            },
                        ]
                    });
                });
            }
        },
        productCarousel: function(){
            var productSlideshow =  $('.j-slideshow--products'); 
            if(productSlideshow.length > 0){
                productSlideshow.slick({
                    dots: false,
                    arrows: true,
                    infinite: true,
                    slidesToShow: 4,
                    nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                    prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
                    responsive: [
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 3,
                          }
                        },
                        {
                          breakpoint: 800,
                          settings: {
                            slidesToShow: 2,
                          }
                        },
                        {
                          breakpoint: 500,
                          settings: {
                            slidesToShow: 1,
                          }
                        },
                    ]
                });
            }
        },
        productVideos: function(){
            var productSlideshow =  $('.j-slideshow--videos'); 
            if(productSlideshow.length > 0){
                productSlideshow.slick({
                    dots: false,
                    arrows: true,
                    infinite: true,
                    nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                    prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>'
                });
            }
        },


        productFilters: function(){
             // JavaScript to be fired on the home page
             var container = $('.j-product-filters');
             // when a checkbox is selected update the url
             container.on('change', '.j-product-filters--option', function(){ 
                 // Holder
                 var categories = [];
                 // Loop through selected checkboxes
                 $.each( container.find('.j-product-filters--option:checked'), function(){
                     // add id to the array
                     categories.push( $(this).val() );
                 });
                 // grab the button
                 var button = container.find('.j-product-filters--submit');
                 // Update the button with the data-href + the selected terms joined by ,
                 button.attr('href', button.data('href') + '?pf=' + categories.join(',') );
             });


             // the selector will match all input controls of type :checkbox
            // and attach a click event handler 
            container.on('click', '.j-product-filters--option', function() {
                var $box = $(this);
                if ($box.is(":checked")) {
                    var group = "input:checkbox[name='" + $box.attr("name") + "']";
                    $(group).prop("checked", false);
                    $box.prop("checked", true);
                } else {
                    $box.prop("checked", false);
                }
            });
        },

        searchSuggestions: function(){
            var searchInput = $('.j-products-search');
            if(searchInput.length > 0){

                searchInput.autoComplete({
                    minChars: 2,
                    onSelect: function(e, term, item){
                        window.location.href = item.data('url');
                    },
                    source: function(term, suggest){

                        term = term.toLowerCase();
                        term = term.replace(/-/g,'').replace(/_/g,'').replace(/ /g,'');
                        var choices = PRODUCT_SEARCH_SUGGESTIONS;
                        var matches = [];
                        for (i=0; i<choices.length; i++){
                            if (~choices[i][0].toLowerCase().indexOf(term)) {
                                matches.push(choices[i]);
                            }
                        }
                        suggest(matches);
                    },
                    renderItem: function (item, search){
                        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
                        return '<div class="autocomplete-suggestion" data-url="'+item[2]+'" data-val="' + item[1] + '">' + item[1].replace(re, "<b>$1</b>") + '</div>';
                    },
                });
            }
        },

        ctaPopups: function(){
        }
        
    };

}(window.jQuery, window, document)); // End of jQuery wrapper