/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {


            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                var body = $('body');
                header.on('click', '.j-menu-toggle', function () {
                    header.toggleClass('s-menu-open');
                    body.toggleClass('no-scroll__mobile');
                });
             
                header.find('.sub-menu').siblings('a').after('<span class="menu-slide"><span class="icon-divider"></span></span>');
                header.find('.menu-slide').on('click',function(e){
                    $(this).toggleClass('open');
                    e.preventDefault();
                    e.stopPropagation();
                    $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                });

                window.PageFunctions.accordion();
                window.PageFunctions.testimonial();
                window.PageFunctions.bannerCarousel();
                window.PageFunctions.calloutCarousel();
                window.PageFunctions.videoCarousel();
                window.PageFunctions.searchSuggestions();
                
                tippy.delegate('body', {
                    target: '.o-tooltip'
                });

                $(window).on('resize', setBreadcrumbPosition );
                setBreadcrumbPosition();
                
                function setBreadcrumbPosition(){
                    $('.l-breadcrumbs').css('top', $('.o-header').outerHeight() );
                }
            }
        },
        // Home page
        'single_product': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {

                window.PageFunctions.productGalleryCarousel();
                window.PageFunctions.productCarousel();
                window.PageFunctions.productVideos();
            }
        },
        // Home page
        'page_template_resources_inner': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                window.resourcesFunctions.setup();
            }
        },
        // Home page
        'post_type_archive_product': {
            init: function () {
                window.PageFunctions.productFilters();
            },
            finalize: function () {
            }
        },
        // Home page
        'tax_product_category': {
            init: function () {
                window.PageFunctions.productFilters();
            },
            finalize: function () {
            }
        },
        
        // Power Tool Template
        'page_template_power_tool': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                window.powerTool.setup();

                $('.pt-results').on('click', function(e){
                    if (e.target !== this)
                    return;
                    e.stopPropagation();
                    if(document.getElementById("vue-popup-status").checked){
                        $('#vue-popup-status').click();
                    }
                });

                
                $('.pt-email--toggle').on('click',function(){
                    $('#vue-email-status').click();
                    // $('.pt-power-tool').toggleClass('emailActive');
                });
                $('.pt-popup--close').on('click',function(){
                    $('#vue-popup-status').click();
                });


                $('.pt-cta-popup--close').on('click',function(){
                    $('.pt-power-tool').removeClass('is-popup-active__instructions is-popup-active__instructions--initial is-popup-active__rules_assumptions  is-popup-active__results_disclaimers');
                });
                $('.pt-inverter-cta--item').on('click',function(){
                    $('.pt-power-tool').addClass('is-popup-active__' + $(this).data('popup') );
                });


                $('.pt-cta-popup--container').on('click', function(e){
                    if (e.target !== this)
                    return;
                    e.stopPropagation();
                    $('.pt-cta-popup--close').click();
                });
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

