(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.resourcesFunctions = {

        setup: function(){
            this.productDocumentation();
            this.faqs();
            this.videoCarousel();
        },
        productDocumentation : function (){
            var table = $('.j-product-documentation');

            if(table.length > 0){
                table.on('keyup', '.j-product-documentation--search', function() {
                    var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
                    table.find('.r-product-documentation--row').show().filter(function() {
                        var text = $(this).data('model').replace(/\s+/g, ' ').toLowerCase();
                        return !~text.indexOf(val);
                    }).hide();
                });
            }
        },
        faqs : function (){
            var faqs = $('.j-faqs');
            if(faqs.length > 0){
                this.faqsSearch(faqs);
                this.faqsToggle(faqs);
            }
        },
        faqsSearch: function (faqs){
            faqs.on('keyup', '.j-faqs--search', function() {
                var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
                faqs.find('.j-faqs--single').show().addClass('displayed').filter(function() {
                    var text = $(this).find('.j-faqs--question').text().replace(/\s+/g, ' ').toLowerCase() +  $(this).find('.j-faqs--answer').text().replace(/\s+/g, ' ').toLowerCase();
                    return !~text.indexOf(val);
                }).hide().removeClass('displayed');
                if(faqs.find('.j-faqs--single.displayed').length > 1){
                    faqs.find('.j-faqs--faqs').removeClass('r-faqs--faqs__no-columns');
                }else{
                    faqs.find('.j-faqs--faqs').addClass('r-faqs--faqs__no-columns');
                }
            });
        },
        faqsToggle: function(faqs){
            faqs.on('click','.j-faqs--toggle',function(){
                $(this).parents('.j-faqs--single').find('.j-faqs--answer').slideToggle().toggleClass('open');
            });
        },
        
        videoCarousel: function(){
            var videoSlideshow =  $('.j-resource-videos'); 
            if(videoSlideshow.length > 0){
                videoSlideshow.each(function(){
                    var _this = $(this);
                    _this.find('.j-videos--slideshow__resource').slick({
                        dots: true,
                        arrows: true,
                        infinite: true,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        nextArrow: '<button type="button" class="slick-next"><span class="icon-arrow-right"></span></button>',
                        prevArrow: '<button type="button" class="slick-prev"><span class="icon-arrow-left"></span></button>',
                        appendArrows: _this.find('.o-carousel--controls'),
                        appendDots: _this.find('.o-carousel--controls'),
                        responsive: [
                            {
                              breakpoint: 1000,
                              settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                              }
                            },
                            {
                              breakpoint: 800,
                              settings: {
                                slidesToShow: 2,
                                slidesToScroll:2,
                              }
                            },
                            {
                              breakpoint: 500,
                              settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                              }
                            },
                        ]
                    });
                });
            }
        },
        
    };

}(window.jQuery, window, document)); // End of jQuery wrapper